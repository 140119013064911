/* /src/pages/Dashboard/components/MyListings/MyListingDashboard/Tabs/Offers/components/OfferView/components/PDFViewer/PDFViewer.css */

.pdf-viewer-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(26, 26, 26, 0.95);
  display: flex;
  flex-direction: column;
  z-index: 1000;
  overflow: hidden;
}

.pdf-viewer-header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
  color: white;
  height: 60px;
}

.pdf-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 80%;
}

.pdf-title {
  font-size: 1.2rem;
  margin: 0;
  text-align: center;
}

.pdf-type {
  font-size: 0.9rem;
  margin: 0.25rem 0 0;
  opacity: 0.8;
}

.pdfviewer-close-button {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pdfviewer-close-button:hover {
  opacity: 0.8;
}

.pdf-viewer-container {
  flex: 1;
  overflow-y: auto;
  margin-top: 60px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  scrollbar-width: thin;
  scrollbar-color: rgba(255, 255, 255, 0.3) transparent;
}

.react-pdf__Document {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100%;
}

.react-pdf__Page {
  margin: 0 auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  background-color: white;
  position: relative;
}

.react-pdf__Page__canvas {
  display: block;
  user-select: none;
  margin: 0 auto;
}

.react-pdf__Page__textContent {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  opacity: 0.25;
  line-height: 1.5;
  user-select: text;
  pointer-events: none;
}

.react-pdf__Page__textContent span {
  color: transparent;
  position: absolute;
  white-space: pre;
  cursor: text;
  transform-origin: 0% 0%;
}

.react-pdf__Page__annotations {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  pointer-events: none;
}

::selection {
  background: rgba(0, 0, 255, 0.3);
}

::-moz-selection {
  background: rgba(0, 0, 255, 0.3);
}

.pdf-toolbar-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
  opacity: 0;
  transform: translateY(100%);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.pdf-toolbar-container.visible {
  opacity: 1;
  transform: translateY(0);
}

.pdf-toolbar {
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 4px;
  padding: 0.5rem;
  gap: 0.5rem;
}

.pdf-toolbar button,
.pdf-toolbar .page-info {
  background: none;
  border: none;
  color: white;
  font-size: 1rem;
  padding: 0.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

.pdf-toolbar button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.pdf-toolbar button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.page-info {
  margin: 0 0.5rem;
  font-size: 0.9rem;
  user-select: none;
}

.pdf-spinner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5;
}

.pdf-spinner {
  width: 50px;
  height: 50px;
  border: 5px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: white;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.pdf-page-container {
  margin: 1rem 0;
  position: relative;
  display: flex;
  justify-content: center;
}

.pdf-page-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #666;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-size: 0.9rem;
}

.pdf-page-placeholder {
  width: 595px;
  height: 842px;
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #666;
  font-size: 1rem;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.pdf-error-message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 0, 0, 0.1);
  color: white;
  padding: 1rem;
  border-radius: 4px;
  border: 1px solid rgba(255, 0, 0, 0.3);
  max-width: 80%;
  text-align: center;
}