/* /CreateListingPackage/CreateListingPackage.css */

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

.clp-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.clp-content {
  background: #ffffff;
  padding: 2rem 3rem;
  border-radius: 20px;
  width: 600px; /* Fixed width */
  height: 700px; /* Fixed height */
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow-y: auto;
  animation: fadeIn 0.3s ease;
  display: flex;
  flex-direction: column;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.clp-title {
  font-size: 1.75rem;
  margin: 0 0 1.5rem;
  text-align: center;
  color: #333333;
  font-weight: 600;
}

.clp-divider {
  border: none;
  height: 1px;
  background-color: #e0e0e0;
  margin: 1rem 0 2rem;
}

.clp-step {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.clp-step h2 {
  font-size: 1.25rem;
  margin-bottom: 1.5rem;
  color: #555555;
  font-weight: 500;
}

.clp-form-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.clp-form-row {
  display: flex;
  gap: 1rem;
  margin-bottom: 0.5rem;
}

.clp-form-group {
  flex: 1;
  min-width: 0;
}

.clp-form-group.full-width {
  width: 100%;
}

.clp-input,
.clp-textarea,
.clp-select {
  width: 100%;
  padding: 0.75rem 1rem;
  border: 1px solid #dddddd;
  border-radius: 8px;
  font-size: 0.95rem;
  font-family: 'Inter', sans-serif;
  box-sizing: border-box;
  transition: all 0.2s ease;
  background-color: #fff;
}

.clp-input:focus,
.clp-textarea:focus,
.clp-select:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.1);
}

.clp-textarea {
  resize: vertical;
  min-height: 100px;
  max-height: 200px;
}

.clp-select {
  background-image: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path fill="%230056b3" d="M6.26 7.29L10 11l3.74-3.71a.75.75 0 0 1 1.07 1.05l-4.5 4.5a.75.75 0 0 1-1.06 0l-4.5-4.5a.75.75 0 1 1 1.06-1.05z"/></svg>');
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 1rem;
  padding-right: 2.5rem;
  cursor: pointer;
}

.clp-error {
  color: #dc3545;
  font-size: 0.8rem;
  margin-top: 0.25rem;
  font-weight: 500;
}

.clp-button-container {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin-top: 2rem;
  padding: 1rem 0;
  border-top: 1px solid #e0e0e0;
}

.clp-back-button,
.clp-next-button {
  flex: 1;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: 500;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.clp-back-button {
  background-color: #f8f9fa;
  color: #495057;
  border: 1px solid #dee2e6;
}

.clp-next-button {
  background-color: #007bff;
  color: #ffffff;
}

.clp-back-button:hover {
  background-color: #e9ecef;
}

.clp-next-button:hover {
  background-color: #0056b3;
}

.clp-close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: transparent;
  border: none;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #666;
  font-size: 1.5rem;
  transition: all 0.2s ease;
}

.clp-close-button:hover {
  color: #333;
}

.clp-close-button:before {
  content: '×';
}

.clp-input-suffix-wrapper {
  position: relative;
  width: 100%;
}

.clp-input-suffix {
  position: absolute;
  right: 2rem;
  top: 50%;
  transform: translateY(-50%);
  color: #666;
  font-size: 0.9rem;
  pointer-events: none;
  user-select: none;
}

.clp-input-suffix-wrapper input {
  padding-right: 4.5rem;
}

/* Spinner styles */
.clp-spinner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
  border-radius: 20px;
}

.clp-spinner {
  width: 40px;
  height: 40px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #007bff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Photo preview styles */
.photo-preview-container {
  margin: 1rem 0;
  padding: 1rem;
  background-color: #f8f9fa;
  border-radius: 8px;
  border: 2px dashed #dee2e6;
}

.photo-preview-list {
  display: flex;
  gap: 1rem;
  overflow-x: auto;
  padding: 1rem;
  min-height: 150px;
  align-items: flex-start;
}

.photo-preview-item {
  position: relative;
  flex: 0 0 auto;
  width: 120px;
  height: 120px;
  border-radius: 8px;
  overflow: hidden;
  cursor: grab;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
}

.photo-preview-item:hover {
  transform: scale(1.05);
}

.photo-preview-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.photo-preview-order {
  position: absolute;
  top: 8px;
  left: 8px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.8rem;
  font-weight: bold;
}

/* Scrollbar styles */
.clp-content::-webkit-scrollbar {
  width: 8px;
}

.clp-content::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.clp-content::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.clp-content::-webkit-scrollbar-thumb:hover {
  background: #555;
}
